<!--

    Copyright (C) 2025 Das Land Schleswig-Holstein vertreten durch den
    Ministerpräsidenten des Landes Schleswig-Holstein
    Staatskanzlei
    Abteilung Digitalisierung und zentrales IT-Management der Landesregierung

    Lizenziert unter der EUPL, Version 1.2 oder - sobald
    diese von der Europäischen Kommission genehmigt wurden -
    Folgeversionen der EUPL ("Lizenz");
    Sie dürfen dieses Werk ausschließlich gemäß
    dieser Lizenz nutzen.
    Eine Kopie der Lizenz finden Sie hier:

    https://joinup.ec.europa.eu/collection/eupl/eupl-text-eupl-12

    Sofern nicht durch anwendbare Rechtsvorschriften
    gefordert oder in schriftlicher Form vereinbart, wird
    die unter der Lizenz verbreitete Software "so wie sie
    ist", OHNE JEGLICHE GEWÄHRLEISTUNG ODER BEDINGUNGEN -
    ausdrücklich oder stillschweigend - verbreitet.
    Die sprachspezifischen Genehmigungen und Beschränkungen
    unter der Lizenz sind dem Lizenztext zu entnehmen.

-->
<button
  mat-icon-button
  data-test-class="icon-button"
  [disabled]="isDisabled"
  [tooltip]="toolTip"
  tooltipAriaType="aria-labelledby"
  [matMenuTriggerFor]="matMenuTriggerFor"
  (click)="clickEmitter.emit($event)"
  type="button"
>
  <mat-icon *ngIf="icon" data-test-class="icon" [style.visibility]="isDisabled ? 'hidden' : 'visible'">
    {{ icon }}
  </mat-icon>

  <mat-icon *ngIf="svgIcon" data-test-class="icon" [svgIcon]="svgIcon" [style.visibility]="isDisabled ? 'hidden' : 'visible'" />

  <ozgcloud-spinner [stateResource]="getStateResource()" [diameter]="22" [show]="showSpinner" padding="0" />
</button>
