/*
 * Copyright (C) 2025 Das Land Schleswig-Holstein vertreten durch den
 * Ministerpräsidenten des Landes Schleswig-Holstein
 * Staatskanzlei
 * Abteilung Digitalisierung und zentrales IT-Management der Landesregierung
 *
 * Lizenziert unter der EUPL, Version 1.2 oder - sobald
 * diese von der Europäischen Kommission genehmigt wurden -
 * Folgeversionen der EUPL ("Lizenz");
 * Sie dürfen dieses Werk ausschließlich gemäß
 * dieser Lizenz nutzen.
 * Eine Kopie der Lizenz finden Sie hier:
 *
 * https://joinup.ec.europa.eu/collection/eupl/eupl-text-eupl-12
 *
 * Sofern nicht durch anwendbare Rechtsvorschriften
 * gefordert oder in schriftlicher Form vereinbart, wird
 * die unter der Lizenz verbreitete Software "so wie sie
 * ist", OHNE JEGLICHE GEWÄHRLEISTUNG ODER BEDINGUNGEN -
 * ausdrücklich oder stillschweigend - verbreitet.
 * Die sprachspezifischen Genehmigungen und Beschränkungen
 * unter der Lizenz sind dem Lizenztext zu entnehmen.
 */
import { isEscapeKey } from '@alfa-client/tech-shared';
import { CdkTrapFocus } from '@angular/cdk/a11y';
import { CommonModule } from '@angular/common';
import { Component, ElementRef, EventEmitter, HostListener, Input, Output, ViewChild } from '@angular/core';
import { twMerge } from 'tailwind-merge';

@Component({
  selector: 'ods-dropdown-menu',
  standalone: true,
  imports: [CommonModule, CdkTrapFocus],
  template: ` <div class="relative w-fit">
    <button
      [ngClass]="[twMerge('block w-fit outline-2 outline-offset-2 outline-focus empty:hidden', buttonClass)]"
      (click)="handleButtonClick()"
      [attr.aria-expanded]="isPopupOpen"
      aria-haspopup="true"
      [attr.aria-label]="label"
      [attr.data-test-id]="buttonTestId"
      [class.ods-focused]="isButtonFocused"
      (focus)="toggleFocusedButton()"
      (blur)="toggleFocusedButton()"
      #button
    >
      <ng-content select="[button-content]" />
    </button>
    <div
      *ngIf="isPopupOpen"
      class="absolute z-50 max-h-120 min-w-44 max-w-96 animate-fadeIn overflow-y-auto rounded bg-dropdownBg shadow-md focus:outline-none"
      [ngClass]="alignTo === 'left' ? 'right-0' : 'left-0'"
      role="menu"
      aria-modal="true"
      tabIndex="-1"
      cdkTrapFocus
      #popupList
    >
      <ng-content />
    </div>
  </div>`,
})
export class DropdownMenuComponent {
  @Input() alignTo: 'left' | 'right' = 'left';
  @Input() label: string = '';
  @Input() buttonClass: string = '';
  @Input() buttonTestId: string = 'dropdown-button';

  @Output() opened: EventEmitter<void> = new EventEmitter<void>();
  @Output() closed: EventEmitter<void> = new EventEmitter<void>();

  public isPopupOpen: boolean = false;
  public isButtonFocused: boolean = false;

  readonly twMerge = twMerge;

  @ViewChild('button') buttonRef: ElementRef<HTMLButtonElement>;
  @ViewChild('popupList') popupListRef: ElementRef<HTMLUListElement>;

  @HostListener('document:keydown', ['$event'])
  onKeydownHandler(e: KeyboardEvent): void {
    if (this.isPopupClosed()) return;
    if (isEscapeKey(e)) this.closePopupAndFocusButton();
  }

  @HostListener('document:click', ['$event'])
  onClickHandler(e: MouseEvent): void {
    if (this.isPopupClosed()) return;
    if (!this.buttonRef.nativeElement.contains(e.target as HTMLElement)) {
      this.closePopupAndFocusButton();
    }
  }

  handleButtonClick(): void {
    this.togglePopup();
    if (this.isPopupOpen) this.focusList();
  }

  focusList(): void {
    setTimeout(() => this.popupListRef.nativeElement.focus());
  }

  togglePopup(): void {
    this.isPopupOpen = !this.isPopupOpen;
    if (this.isPopupOpen) {
      this.opened.emit();
    } else {
      this.closed.emit();
    }
  }

  toggleFocusedButton(): void {
    this.isButtonFocused = !this.isButtonFocused;
  }

  closePopupAndFocusButton(): void {
    this.isPopupOpen = false;
    this.buttonRef.nativeElement.focus();
  }

  isPopupClosed(): boolean {
    return !this.isPopupOpen;
  }

  closeMenu() {
    this.isPopupOpen = false;
    this.closed.emit();
  }
}
