import { CommonModule } from '@angular/common';
import { Component, Input } from '@angular/core';
import { twMerge } from 'tailwind-merge';
import { iconVariants, IconVariants } from '../iconVariants';

@Component({
  selector: 'ods-archive-icon',
  standalone: true,
  imports: [CommonModule],
  template: `<svg
    viewBox="0 0 24 24"
    xmlns="http://www.w3.org/2000/svg"
    [ngClass]="twMerge(iconVariants({ size }), 'fill-primary', class)"
    aria-hidden="true"
  >
    <path
      d="M4.96804 21L2.85693 9H21.8569L19.7458 21H4.96804ZM6.7361 19H17.9778L19.3236 11H5.39027L6.7361 19ZM10.2458 15H14.468C14.7671 15 15.0178 14.9042 15.2201 14.7125C15.4224 14.5208 15.5236 14.2833 15.5236 14C15.5236 13.7167 15.4224 13.4792 15.2201 13.2875C15.0178 13.0958 14.7671 13 14.468 13H10.2458C9.94675 13 9.69605 13.0958 9.49374 13.2875C9.29142 13.4792 9.19027 13.7167 9.19027 14C9.19027 14.2833 9.29142 14.5208 9.49374 14.7125C9.69605 14.9042 9.94675 15 10.2458 15ZM6.0236 8C5.72453 8 5.47383 7.90417 5.27152 7.7125C5.0692 7.52083 4.96804 7.28333 4.96804 7C4.96804 6.71667 5.0692 6.47917 5.27152 6.2875C5.47383 6.09583 5.72453 6 6.0236 6H18.6903C18.9893 6 19.24 6.09583 19.4424 6.2875C19.6447 6.47917 19.7458 6.71667 19.7458 7C19.7458 7.28333 19.6447 7.52083 19.4424 7.7125C19.24 7.90417 18.9893 8 18.6903 8H6.0236ZM8.13471 5C7.83564 5 7.58494 4.90417 7.38263 4.7125C7.18031 4.52083 7.07916 4.28333 7.07916 4C7.07916 3.71667 7.18031 3.47917 7.38263 3.2875C7.58494 3.09583 7.83564 3 8.13471 3H16.5792C16.8782 3 17.1289 3.09583 17.3312 3.2875C17.5336 3.47917 17.6347 3.71667 17.6347 4C17.6347 4.28333 17.5336 4.52083 17.3312 4.7125C17.1289 4.90417 16.8782 5 16.5792 5H8.13471Z"
    />
    <path
      d="M5 21C1.68629 21 -1 18.3137 -1 15C-1 11.6863 1.68629 9 5 9C8.31371 9 11 11.6863 11 15C11 18.3137 8.31371 21 5 21Z"
      class="fill-whitetext"
    />
    <path
      d="M5.00078 19.9999C2.23936 19.9999 0.00078249 17.7613 0.000782609 14.9999C0.000782728 12.2385 2.23936 9.99991 5.00078 9.99991C7.76221 9.99991 10.0008 12.2385 10.0008 14.9999C10.0008 17.7613 7.76221 19.9999 5.00078 19.9999Z"
    />
    <path
      d="M5.00078 19.9999C2.23936 19.9999 0.00078249 17.7613 0.000782609 14.9999C0.000782728 12.2385 2.23936 9.99991 5.00078 9.99991C7.76221 9.99991 10.0008 12.2385 10.0008 14.9999C10.0008 17.7613 7.76221 19.9999 5.00078 19.9999Z"
    />
    <path
      d="M7.99997 14.9999L4.99997 11.9999L3.94997 13.0499L5.14997 14.2499H1.99997V15.7499H5.14997L3.94997 16.9499L4.99997 17.9999L7.99997 14.9999Z"
      class="fill-whitetext"
    />
  </svg> `,
})
export class ArchiveIconComponent {
  @Input() size: IconVariants['size'] = 'medium';
  @Input() class: string = undefined;

  readonly iconVariants = iconVariants;
  readonly twMerge = twMerge;
}
