/*
 * Copyright (C) 2023 Das Land Schleswig-Holstein vertreten durch den
 * Ministerpräsidenten des Landes Schleswig-Holstein
 * Staatskanzlei
 * Abteilung Digitalisierung und zentrales IT-Management der Landesregierung
 *
 * Lizenziert unter der EUPL, Version 1.2 oder - sobald
 * diese von der Europäischen Kommission genehmigt wurden -
 * Folgeversionen der EUPL ("Lizenz");
 * Sie dürfen dieses Werk ausschließlich gemäß
 * dieser Lizenz nutzen.
 * Eine Kopie der Lizenz finden Sie hier:
 *
 * https://joinup.ec.europa.eu/collection/eupl/eupl-text-eupl-12
 *
 * Sofern nicht durch anwendbare Rechtsvorschriften
 * gefordert oder in schriftlicher Form vereinbart, wird
 * die unter der Lizenz verbreitete Software "so wie sie
 * ist", OHNE JEGLICHE GEWÄHRLEISTUNG ODER BEDINGUNGEN -
 * ausdrücklich oder stillschweigend - verbreitet.
 * Die sprachspezifischen Genehmigungen und Beschränkungen
 * unter der Lizenz sind dem Lizenztext zu entnehmen.
 */
import { TechSharedModule } from '@alfa-client/tech-shared';
import { UiModule } from '@alfa-client/ui';
import { VorgangSharedModule } from '@alfa-client/vorgang-shared';
import { CommonModule } from '@angular/common';
import { NgModule } from '@angular/core';
import { RouterModule } from '@angular/router';
import { TooltipDirective } from '@ods/system';
import { AktenzeichenComponent } from './aktenzeichen/aktenzeichen.component';
import { VorgangNummerComponent } from './vorgang-nummer/vorgang-nummer.component';
import { VorgangSearchContainerComponent } from './vorgang-search-container/vorgang-search-container.component';
import { VorgangSearchAutocompleteOptionsContentComponent } from './vorgang-search-container/vorgang-search/vorgang-search-autocomplete-options-content/vorgang-search-autocomplete-options-content.component';
import { VorgangSearchClearButtonComponent } from './vorgang-search-container/vorgang-search/vorgang-search-clear-button/vorgang-search-clear-button.component';
import { VorgangSearchComponent } from './vorgang-search-container/vorgang-search/vorgang-search.component';
import { VorgangStatusDotComponent } from './vorgang-status-dot/vorgang-status-dot.component';
import { VorgangStatusTextComponent } from './vorgang-status-text/vorgang-status-text.component';
import { WiedervorlageIconComponent } from './wiedervorlage-icon/wiedervorlage-icon.component';

@NgModule({
  imports: [CommonModule, VorgangSharedModule, UiModule, RouterModule, TechSharedModule, TooltipDirective],
  declarations: [
    VorgangSearchContainerComponent,
    VorgangSearchComponent,
    AktenzeichenComponent,
    VorgangStatusDotComponent,
    VorgangSearchAutocompleteOptionsContentComponent,
    VorgangSearchClearButtonComponent,
    VorgangNummerComponent,
    VorgangStatusTextComponent,
    WiedervorlageIconComponent,
  ],
  exports: [
    VorgangSearchContainerComponent,
    AktenzeichenComponent,
    VorgangStatusDotComponent,
    VorgangSearchAutocompleteOptionsContentComponent,
    VorgangNummerComponent,
    VorgangStatusTextComponent,
    WiedervorlageIconComponent,
  ],
})
export class VorgangSharedUiModule {}
