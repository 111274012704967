<!--

    Copyright (C) 2023 Das Land Schleswig-Holstein vertreten durch den
    Ministerpräsidenten des Landes Schleswig-Holstein
    Staatskanzlei
    Abteilung Digitalisierung und zentrales IT-Management der Landesregierung

    Lizenziert unter der EUPL, Version 1.2 oder - sobald
    diese von der Europäischen Kommission genehmigt wurden -
    Folgeversionen der EUPL ("Lizenz");
    Sie dürfen dieses Werk ausschließlich gemäß
    dieser Lizenz nutzen.
    Eine Kopie der Lizenz finden Sie hier:

    https://joinup.ec.europa.eu/collection/eupl/eupl-text-eupl-12

    Sofern nicht durch anwendbare Rechtsvorschriften
    gefordert oder in schriftlicher Form vereinbart, wird
    die unter der Lizenz verbreitete Software "so wie sie
    ist", OHNE JEGLICHE GEWÄHRLEISTUNG ODER BEDINGUNGEN -
    ausdrücklich oder stillschweigend - verbreitet.
    Die sprachspezifischen Genehmigungen und Beschränkungen
    unter der Lizenz sind dem Lizenztext zu entnehmen.

-->
<ods-dropdown-menu
  buttonClass="rounded-lg p-2 hover:bg-neutral-100 focus:bg-neutral-200 focus:outline-none dark:hover:bg-neutral-700 dark:focus:bg-neutral-600"
>
  <div
    button-content
    *ngIf="enabled"
    data-test-id="help-menu-button"
    class="flex items-center text-ozggray-800 dark:text-ozggray-300"
  >
    <ods-help-icon />
    <div class="ml-1 text-sm font-medium">Hilfe</div>
  </div>

  <ods-dropdown-menu-text-item
    *ngIf="apiRootStateResource?.resource | hasLink: apiRootLinkRel.DOCUMENTATIONS"
    class="border-b border-b-grayborder border-t-grayborder bg-whitetext first:border-t"
    title="Benutzerleitfaden"
    description="Alle Funktionen der Allgemeinen Fachanwendung (Alfa) erklärt."
  >
    <ods-file-icon icon fileType="pdf" size="large"></ods-file-icon>
    <alfa-documentation
      additionalContent
      data-test-id="documentations-component"
      [url]="apiRootStateResource.resource | getUrl: apiRootLinkRel.DOCUMENTATIONS"
    >
    </alfa-documentation>
  </ods-dropdown-menu-text-item>
</ods-dropdown-menu>
