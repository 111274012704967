/*
 * Copyright (C) 2024 Das Land Schleswig-Holstein vertreten durch den
 * Ministerpräsidenten des Landes Schleswig-Holstein
 * Staatskanzlei
 * Abteilung Digitalisierung und zentrales IT-Management der Landesregierung
 *
 * Lizenziert unter der EUPL, Version 1.2 oder - sobald
 * diese von der Europäischen Kommission genehmigt wurden -
 * Folgeversionen der EUPL ("Lizenz");
 * Sie dürfen dieses Werk ausschließlich gemäß
 * dieser Lizenz nutzen.
 * Eine Kopie der Lizenz finden Sie hier:
 *
 * https://joinup.ec.europa.eu/collection/eupl/eupl-text-eupl-12
 *
 * Sofern nicht durch anwendbare Rechtsvorschriften
 * gefordert oder in schriftlicher Form vereinbart, wird
 * die unter der Lizenz verbreitete Software "so wie sie
 * ist", OHNE JEGLICHE GEWÄHRLEISTUNG ODER BEDINGUNGEN -
 * ausdrücklich oder stillschweigend - verbreitet.
 * Die sprachspezifischen Genehmigungen und Beschränkungen
 * unter der Lizenz sind dem Lizenztext zu entnehmen.
 */
import { NgClass } from '@angular/common';
import { Component, Input } from '@angular/core';
import { twMerge } from 'tailwind-merge';

import { IconVariants, iconVariants } from '../iconVariants';

@Component({
  selector: 'ods-save-icon',
  standalone: true,
  imports: [NgClass],
  template: ` <svg
    [ngClass]="[twMerge(iconVariants({ size }), 'fill-primary', class)]"
    aria-hidden="true"
    viewBox="0 0 32 32"
    xmlns="http://www.w3.org/2000/svg"
  >
    <path
      d="M25.3333 16V25.3333H6.66667V16H4V25.3333C4 26.8 5.2 28 6.66667 28H25.3333C26.8 28 28 26.8 28 25.3333V16H25.3333ZM17.3333 16.8933L20.7867 13.4533L22.6667 15.3333L16 22L9.33333 15.3333L11.2133 13.4533L14.6667 16.8933V4H17.3333V16.8933Z"
    />
  </svg>`,
})
export class SaveIconComponent {
  @Input() size: IconVariants['size'] = 'medium';
  @Input() class: string = undefined;

  iconVariants = iconVariants;
  twMerge = twMerge;
}
